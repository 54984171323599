<pg-layout-home>
  <main>
    <div class="bg-primary">
      <pg-banner-carousel></pg-banner-carousel>
    </div>
    <!-- <div class="home-header">
      <section class="container header-content">
        <div class="header-text">
          POWER UP WITH
          <div class="home-plasma-img">
            <img src="assets/img/logo/plasma-text.svg" alt="" />
          </div>
        </div>

        <div>
          <a routerLink="/educators" class="btn btn-secondary header-btn text-uppercase">I'm an Educator</a>
        </div>
        <div>
          <a routerLink="/auth/login" class="btn btn-secondary header-btn text-uppercase">I'm a Student</a>
        </div>
      </section>
    </div> -->
    <div class="power-up-section bg-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <iframe
              class="rounded"
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/li7WS_TN_Pc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-5 text-center power-up-content">
            <div class="title-text text-primary">POWER UP</div>
            <p class="text-start px-5 text-dark power-up-text text-center">
              Plasma is powering up science classrooms across the country by improving student outcomes and empowering teachers! The unique fusion of 3D gameplay, learning
              standards, and STEM career paths enables teachers to enrich their science lessons and motivate even the hardest to reach students. Check out this video to learn more
              and click the button below to start powering up your district!
            </p>
            <div data-aos="fade-in">
              <a routerLink="/contact" class="btn-secondary btn fw-bold text-lg">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pg-testimonials></pg-testimonials>
    <div class="home-stats-section">
      <section class="container home-stats-content text-white text-center">
        <div class="home-stats-item" data-aos="fade-up" data-aos-delay="100">
          <div class="home-stats-img">
            <img src="assets/img/home/stats-circle.svg" alt="" />
          </div>
          <div class="title-text">ENGAGING</div>
          <p class="text-center">Plasma students that completed Episode 1 could expect an increase in their interest in and value for science by +23%</p>
        </div>
        <div class="home-stats-item" data-aos="fade-up">
          <div class="home-stats-img">
            <img class="img-fluid" src="assets/img/home/stats-percent.png" alt="" />
          </div>
          <div class="title-text">EFFECTIVE</div>
          <p class="text-center">+19% increase in correct answers to End-of-course (EOC) questions after 20-30 minutes of gameplay</p>
        </div>
        <div class="home-stats-item" data-aos="fade-up" data-aos-delay="200">
          <div class="home-stats-img">
            <img src="assets/img/home/stats-graph.svg" alt="" />
          </div>
          <div class="title-text">MOTIVATIONAL</div>
          <p class="text-center">+3-7% of students IMPROVED their self-efficacy and utility in chemistry & STEM</p>
        </div>
      </section>
      <section data-aos="fade-in" class="container text-center pb-5">
        <button class="btn btn-secondary text-lg" (click)="open(ResearchModal)">The Research</button>
      </section>
      <ng-template #ResearchModal let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-ResearchModal-title">Whitepaper</h4>
          <button type="button" class="ms-2 mb-1 btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <iframe class="rounded" src="https://dkmzd4tgwtjws.cloudfront.net/public/whitepaper/independent_research_results.pdf" width="100%" height="700px"></iframe>
        </div>
      </ng-template>
    </div>
    <div class="awards-section bg-white">
      <div class="container awards-content text-primary text-center mb-4">
        <div class="awards-item" data-aos="fade-right">
          <div class="awards-img">
            <img src="assets/img/home/award-ribbon-new.svg" alt="" />
          </div>
          <div class="awards-text">International Games and Learning Alliance<br />2020 Best in the World Learning Game</div>
        </div>
        <div class="awards-item" data-aos="fade-up" data-aos-delay="100">
          <div class="awards-img">
            <img src="assets/img/home/award-nsf.svg" alt="" />
          </div>
          <div class="awards-text">National Science Foundation Award Winner</div>
        </div>
        <div class="awards-item" data-aos="fade-left" data-aos-delay="200">
          <div class="awards-img">
            <img src="assets/img/home/award-star.svg" alt="" />
          </div>
          <div class="awards-text">Games For Change<br />2020 Best Learning Game Finalist</div>
        </div>
      </div>
      <div class="container text-center">
        <a data-aos="fade-in" routerLink="/contact" class="btn btn-primary text-lg">Contact Us</a>
      </div>
    </div>
  </main>
</pg-layout-home>
